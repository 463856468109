import { FC, memo } from "react";
// import { FC, memo, useEffect, useState } from "react";
import { SearchBarProps } from "./types";
import { HeaderStyled } from "./styled";
// import { HeaderStyled, LogoWrapper } from "./styled";
import { DynamicSelector } from "..";
// import { isValidImage } from "../../common/helpers";

const SearchBar: FC<SearchBarProps> = ({
  setTab,
  searchError,
  handleShowFilters,
  // storeLogo,
}) => {
  // const [isValidLogo, setIsValidLogo] = useState(false);

  // const testImage = async () => {
  //   const isValid = await isValidImage(storeLogo);
  //   setIsValidLogo(isValid);
  // };
  // useEffect(() => {
  //   testImage();
  // }, []);
  return (
    <HeaderStyled className="search-bar-header-class">
      {/* <div>
        <LogoWrapper>
          {isValidLogo && storeLogo && <img src={storeLogo} alt="logo" width={"100px"} height={"100px"} />}
        </LogoWrapper>
      </div> */}
      <DynamicSelector
        setTab={setTab}
        searchError={searchError}
        handleShowFilters={handleShowFilters}
      />
      {/* <div>
        <LogoWrapper />
      </div> */}
    </HeaderStyled>
  );
};

export default memo(SearchBar);
