import { styled } from "styled-components";

export const ListWrapper = styled.div`
  width: 100%;
  @media screen and (min-width: 1024px) {
    width: calc(100% - 290px);
    padding: 0px 20px 0px 0px;
  }
  @media screen and (min-width: 750px) {
    padding: 0px 20px;
  }
`;
export const ProductsListStyled = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  padding-bottom: 20px;
  gap: 12px;
  grid-template-columns: repeat(
    auto-fill,
    minmax(max(300px, max(100%/5)), 1fr)
  );
  grid-auto-rows: max-content;
  overflow-y: auto;
  padding: 0px 20px 20px 20px;
  @media screen and (min-width: 1024px) {
    padding: 0px 40px 20px 0px;
  }

  > div:last-child {
    height: 4px;
  }
  @media screen and (max-width: 684px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export const ProductsListHorizontal = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 30px;
  padding-bottom: 20px;
  @media screen and (max-width: 684px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;
