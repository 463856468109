import { styled } from "styled-components";
import { StyledProps } from "../../common/types";
import {
  AccordionLabelProps,
  AccordionWrapperProps,
  CheckBoxStyledProps,
} from "./types";

export interface FilterBlockProps {
  hasBorder: boolean;
}

export const Wrapper = styled.aside<AccordionWrapperProps>`
  display: ${({ hideOnMobile }) => (hideOnMobile ? "none" : "flex")};
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  @media (min-width: 1024px) {
    display: flex;
    width: 400px;
    margin-right: 20px;
    padding-left: 40px;
    min-width: 270px;
    max-width: 270px;
  }
`;

export const AccordionWrapperStyled = styled.div`
  overflow-y: auto;
`;

export const AccordionLabelStyled = styled.div<AccordionLabelProps>`
  overflow-y: auto;

  button {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 12px;
    cursor: pointer;
    color: ${({ color }) => color || "##111827"};
    font-size: ${({ fontSize }) => fontSize};
    font-weight: ${({ fontWeight }) => fontWeight};
    text-transform: capitalize;
    background: white !important;
    p {
      font-size: 18px;
      font-weight: 600;
    }
    svg {
      transform: ${({ isExpanded }) =>
        isExpanded ? "rotate(180deg)" : "none"};
    }
  }
`;

export const CheckBoxesList = styled.div`
  display: grid;
  gap: 12px;
  grid-auto-rows: max-content;
  padding: 0 16px;
`;

export const CheckBoxStyled = styled.div<CheckBoxStyledProps>`
  display: ${({ hideCheckBox }) => (hideCheckBox ? "none" : "flex")};
  align-items: center;

  > div {
    display: flex;
    align-items: center;

    input {
      border-radius: 4px;
      width: ${({ width }) => width};
      height: ${({ height }) => height};

      &:checked {
        background: ${({ backgroundColor }) => backgroundColor};
      }
      &:not(:checked):hover {
        background: ${({ hoverBackgroundColor }) => hoverBackgroundColor};
      }
    }

    label {
      color: ${({ color }) => color || "##111827"};
      font-size: ${({ fontSize }) => fontSize};
      font-weight: ${({ fontWeight }) => fontWeight};
      cursor: pointer;
      margin-inline-start: 8px;
      line-height: normal;
    }
  }
`;

export const ButtonSectionStyled = styled.div<StyledProps>`
  display: block;
  padding: 20px 0;
  background-color: #ffffff;

  > hr {
    margin-bottom: 20px;
  }

  > div {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 16px;
  }

  @media (min-width: 1024px) {
    display: none;
  }
`;

export const ClearButtonStyled = styled.button`
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  height: 48px !important;
  padding: 12px !important;
  border-width: 1px !important;
  border-radius: 12px !important;
  border-style: groove !important;
  cursor: pointer !important;
`;

export const CloseButtonStyled = styled.button<StyledProps>`
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  height: 48px !important;
  padding: 12px !important;
  border-width: 1px !important;
  border-radius: 12px !important;
  background-color: #082f49 !important;
  color: #ffffff !important;
  cursor: pointer !important;
`;

export const FilterBlock = styled.div<FilterBlockProps>`
  padding-bottom: 10px;
  border-bottom: ${({ hasBorder }) => hasBorder ? "1px solid #838383" : "none"};
`;
