import { createRoot } from "react-dom/client";
import React from "react";
import App from "./App.tsx";
import "./index.css";
import * as Sentry from "@sentry/react";
import { GlobalProvider } from "./context/index.tsx";
import SearchHeader from "./SearchHeader.tsx";
import { createPortal } from "react-dom";

// Initialize Sentry
Sentry.init({
  dsn: "https://your-dsn-url",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: 1.0,
});

// Define the props for AppWrapper
interface AppWrapperProps {
  store: string | null;
  currency: string | null;
  storeLogo: string | null;
}

const AppWrapper: React.FC<AppWrapperProps> = ({ store, currency, storeLogo }) => {
  const headerContainer = document.getElementById("header-search-bar");

  if (!headerContainer) {
    return null;
  }

  return (
    <GlobalProvider>
        <App store={store} currency={currency} storeLogo={storeLogo} />
        {createPortal(<SearchHeader />, headerContainer)}
    </GlobalProvider>
  );
};

window.addEventListener("load", function () {
  let sptAttribute: string | null = null;
  let shopifyDomain: string | null = null;
  let reservedSptAttribute: string | null = null;
  let storeCurrency: string | null = null;
  let storeLogo: string | null = null;

  const sptAttributeElement = document
    .evaluate("//div[@sptselector]", document, null, XPathResult.ANY_TYPE, null)
    ?.iterateNext() as HTMLElement | null;
  if (sptAttributeElement) {
    sptAttribute = sptAttributeElement.getAttribute("sptselector");
  }

  const reservedSptAttributeElement = document
    .evaluate(
      "//div[@reservedsptselector]",
      document,
      null,
      XPathResult.ANY_TYPE,
      null
    )
    ?.iterateNext() as HTMLElement | null;
  if (reservedSptAttributeElement) {
    reservedSptAttribute = reservedSptAttributeElement.getAttribute(
      "reservedsptselector"
    );
  }

  const shopifyDomainElement = document
    .evaluate(
      "//div[@shopifydomain]",
      document,
      null,
      XPathResult.ANY_TYPE,
      null
    )
    ?.iterateNext() as HTMLElement | null;
  if (shopifyDomainElement) {
    shopifyDomain = shopifyDomainElement.getAttribute("shopifydomain");
  }

  const storeCurrencyElement = document
    .evaluate(
      "//div[@data-current-market]",
      document,
      null,
      XPathResult.ANY_TYPE,
      null
    )
    ?.iterateNext() as HTMLElement | null;
  if (storeCurrencyElement) {
    storeCurrency = storeCurrencyElement.getAttribute("data-current-market");
  }

  const storeLogoElement = document
    .evaluate(
      "//div[@store-logo]",
      document,
      null,
      XPathResult.ANY_TYPE,
      null
    )
    ?.iterateNext() as HTMLElement | null;
  if (storeLogoElement) {
    storeLogo = storeLogoElement.getAttribute("store-logo");
  }

  const sptElement = document.querySelector(`.${sptAttribute}`);
  const reservedSptElement = document.querySelector(`.${reservedSptAttribute}`);

  sptElement?.addEventListener("click", () => {
    const mainElement = document.getElementById("spt-react-app");
    if (mainElement) {
      mainElement.style.display = "block";
    }
  });

  reservedSptElement?.addEventListener("click", () => {
    const mainElement = document.getElementById("spt-react-app");
    if (mainElement) {
      mainElement.style.display = "block";
    }
  });

  // Create a new div for React
  const newDiv = document.createElement("div");
  newDiv.className = "app";
  document.body.appendChild(newDiv); // Append it to the body

  // Ensure that the header container exists
  const headerContainer = document.getElementById("header-search-bar");
  if (!headerContainer) {
    console.error("Header search bar container not found.");
    return;
  }

  // Render the app
  const root = createRoot(newDiv);
  root.render(<AppWrapper store={shopifyDomain} currency={storeCurrency} storeLogo={storeLogo} />);
});
