export enum SelectorsLocalStorage {
  SELECTED_YEAR_KEY_NAME = 'selected-year-key',
  SELECTED_YEAR_VALUE_NAME = 'selected-year-value',
  SELECTED_MAKE_KEY_NAME = 'selected-make-key',
  SELECTED_MAKE_VALUE_NAME = 'selected-make-value',
  SELECTED_MODEL_KEY_NAME = 'selected-model-key',
  SELECTED_MODEL_VALUE_NAME = 'selected-model-value',
  SELECTED_PARTTYPE_KEY_NAME = 'selected-parttype-key',
  SELECTED_PARTTYPE_VALUE_NAME = 'selected-parttype-value',
  SELECTED_PRODUCT_KEY_NAME = 'selected-product-key',
  SELECTED_PRODUCT_VALUE_NAME = 'selected-product-value',
  SELECTED_ENGINE_KEY_NAME = 'selected-engine-key',
  SELECTED_ENGINE_VALUE_NAME = 'selected-engine-value',
  SELECTED_VIN_VALUE_NAME = 'current-vehicle-vin',
  SELECTED_VIN_KEY_NAME = 'selected-vin-key',
  SELECTED_VIN_PRODUCT_NAME = 'selected-vin-product-name',
  SELECTED_VIN_PRODUCT_VALUE = 'selected-vin-product-value',
  VIN_PRODUCT_OPTIONS = 'vin-product-options',
  CURRENT_LICENSE_PLATE = 'current-license-plate',
  SELECTED_LICENSE_PLATE_STATE = 'selected-license-plate-state',
  CURRENT_FITMENT_PART_DATA = "current-fitment-part-data",
  LICENSE_PLATE_PRODUCT_LIST = "license-plate-product-list",
  LICENSE_PLATE_PRODUCT_SELECTED_NAME = "license-plate-product-selected-name",
  LICENSE_PLATE_PRODUCT_SELECTED_VALUE = "license-plate-product-selected-value",
}

export enum SptLocalStorageVariables {
  SPT_FILTERS = 'SPT_FILTERS',
  SPT_SELECTED_FILTERS = 'SPT_SELECTED_FILTERS',
  SPT_PRODUCTS = 'SPT_PRODUCTS',
  SPT_SELECTORS = 'SPT_SELECTORS',
}
