import styled from "styled-components";
import { StyledProps } from "../../types";

interface DropdownStyledProps extends StyledProps {
  isActive?: boolean;
  disabled?: boolean;
  isError?: boolean;
}

interface OptionItemProps {
  selected: boolean;
}

export const DropdownStyled = styled.div<DropdownStyledProps>`
  display: grid;
  grid-auto-rows: max-content;
  grid-template-columns: 100%;
  gap: 6px;
  width: 100%;
  position: relative;

  h2 {
    width: inherit;
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    @media (max-width: 980px) {
      margin-left: 22px;
    }
  }
  > section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 14px;
    width: inherit;
    height: 40px;
    background-color: ${({ disabled }) => (disabled ? "#cdcdcd" : "white")};
    border: 1px solid;
    box-shadow: 1px 1px 2px rgba(16, 24, 40, 0.12);
    border-radius: 8px;
    border-color: transparent !important;
    outline: none;
    color: ${({ color }) => color || "#000000"} !important;
    font-size: ${({ fontSize }) => fontSize || "16px"} !important;
    font-weight: ${({ fontWeight }) => fontWeight || 500} !important;
    line-height: 22px;
    letter-spacing: 0.005em;
    cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
    overflow-y: hidden;

    > div {
      display: flex;
      align-items: center;
      width: calc(100% - 36px);

      > svg {
        margin-right: 8px;
        min-width: 22px;
        height: 22px;
      }
    }

    span {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      color: grey;
    }
    > svg {
      width: 24px;
      transform: ${({ isActive }) => (isActive ? "rotate(180deg)" : "unset")};
    }
  }
`;

export const OptionsList = styled.div<StyledProps>`
  display: grid;
  padding: 8px;
  gap: 4px;
  grid-template-rows: max-content;
  position: absolute;

  z-index: 10;
  max-height: 260px;
  border-radius: 8px;
  border: 1px solid grey;
  background: white;
  box-shadow: 0 10px 45px 0 rgba(14, 40, 83, 0.07);
  cursor: pointer;
  top: 100%;
  width: 100%;
`;

export const OptionItem = styled.div<OptionItemProps>`
  display: flex;
  padding: 4px 6px;
  align-items: center;
  text-align: start;
  border-radius: 4px;
  color: #000000;
  font-size: 16px;
  font-weight: 500;
  line-height: 22.4px;
  letter-spacing: 0.08px;
  background-color: ${({ selected }) => (selected ? "#e8e8e8" : "transparent")};

  &:hover {
    background: #f2f2f2;
  }
`;

export const ListStyled = styled.div`
  overflow-y: auto;
  max-height: 200px;

  > p {
    text-align: center;
  }
`;

export const InputSearchStyled = styled.div`
  position: relative;
  svg {
    z-index: 11;
    width: 14px;
    height: 14px;
    position: absolute;
    top: 50%;
    bottom: 50%;
    left: 8px;
    margin-top: auto;
    margin-bottom: auto;
  }

  input {
    box-sizing: border-box;
    width: 100%;
    position: sticky;
    top: 0;
    z-index: 10;
    padding: 8px;
    padding-left: 30px;
    background: white;
    box-sizing: border-box;
    outline: none !important;
    border: none;
  }
`;
