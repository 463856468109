import { FC, memo, useEffect, useMemo, useRef, useState } from "react";
import { DropdownOption, DropdownProps } from "./types";
import {
  DropdownStyled,
  OptionItem,
  OptionsList,
  InputSearchStyled,
  ListStyled,
} from "./styles";
import useOnClickOutside from "../../hooks/useClickOutside";
import { ArrowDownIcon, SearchIcon } from "..";

const Dropdown: FC<DropdownProps> = ({
  searchPlaceholder,
  value,
  onChange,
  options,
  placeholder,
  color,
  fontSize,
  fontWeight,
  optionsList,
  // isOpened,
}) => {
  const ref = useRef(null);
  // const inputRef = useRef<HTMLInputElement>(null);
  const [isActive, setIsActive] = useState(false);
  const [dropdownOptions, setDropdownOptions] = useState<DropdownOption[]>([]);
  const [searchItem, setSearchItem] = useState("");
  const currentOptionList = optionsList?.length ? optionsList : dropdownOptions;
  useOnClickOutside(ref, () => setIsActive(false));

  useEffect(() => {
    const fetchOptions = async () => {
      // const data: any = await getOptions();
      if (Array.isArray(options)) {
        const optionsList = options
          ?.map((item: any) => ({
            value: item?.id || "",
            label: item?.data || item?.id || "",
          }))
          .filter((item) => !!item.value);
        setDropdownOptions(optionsList);
      } else {
        setDropdownOptions(
          [
            {
              value: options?.id || "",
              label: options?.data || options?.id || "",
            },
          ].filter((item) => !!item.value)
        );
      }
    };
    fetchOptions();
  }, [options]);

  // useEffect(() => {
  //   if (isOpened) {
  //     setIsActive(isOpened);
  //   }
  //   return () => {
  //     setIsActive(false);
  //   };
  // }, [isOpened]);

  // useEffect(() => {
  //   const handleBlur = () => inputRef.current?.focus();
  //   if (isActive && isOpened) {
  //     setTimeout(() => {
  //       inputRef.current?.focus();
  //       inputRef.current?.addEventListener("blur", handleBlur);
  //     }, 300); // Small delay to wait for rendering
  //   }
  // }, [isActive, isOpened]);

  const optionList = useMemo(() => {
    if (!searchItem) return currentOptionList;
    return currentOptionList.filter((item) =>
      item.label.toLocaleLowerCase().includes(searchItem.toLocaleLowerCase())
    );
  }, [currentOptionList, searchItem, value]);

  const isListNotEmpty = isActive && !!currentOptionList?.length;

  const optionName = useMemo(() => {
    const currentOption = currentOptionList?.find(
      (item) => item.label === value
    );
    return currentOption
      ? currentOption.label || currentOption.value
      : placeholder;
  }, [value, currentOptionList, placeholder]);

  return (
    <DropdownStyled
      isActive={isActive}
      disabled={!optionList?.length}
      ref={ref}
      color={color}
      fontSize={fontSize}
      fontWeight={fontWeight}
    >
      <section
        onMouseDown={() => !!optionList?.length && setIsActive(!isActive)}
        className="search-bar-selector-class"
      >
        <span className="selector-text">{optionName}</span>
        <ArrowDownIcon />
      </section>
      {isListNotEmpty ? (
        <OptionsList className="optionList" collapsed={isActive}>
          <InputSearchStyled>
            <SearchIcon />
            <input
              id={"search"}
              type="search"
              value={searchItem}
              onChange={(e) => setSearchItem(e.target?.value || "")}
              placeholder={searchPlaceholder}
              // ref={inputRef}
              autoComplete="off"
            />
          </InputSearchStyled>
          <ListStyled>
            {optionList.map((item) => (
              <OptionItem
                key={`${item.label}-${item.value}`}
                onClick={() => {
                  onChange(item);
                  setIsActive(false);
                }}
                selected={value === String(item.label)}
                className="selector-text"
              >
                {item.label}
              </OptionItem>
            ))}
            {!optionList.length && <p>No results</p>}
          </ListStyled>
        </OptionsList>
      ) : (
        ""
      )}
    </DropdownStyled>
  );
};

export default memo(Dropdown);
