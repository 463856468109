import { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import Dropdown from "../../common/components/Dropdown";
import {
  SelectorsLocalStorage,
  SptLocalStorageVariables,
} from "../../common/constants/localStorage";
import {
  decodeHtml,
  getLocalStorageValue,
  setLocalStorageValue,
} from "../../common/helpers";
import X2JS from "x2js";
import { Wrapper, SearchButtonStyled } from "./styled";
import { Input, Loader } from "../../common/components";
import {
  autoPartsSearch,
  fetchProductsByLicensePlate,
  fetchSMTPData,
} from "../../api/autoParts";
import { statesList } from "../../common/constants/statesList";
import { useGlobalContext } from "../../context";
import { FullWidth } from "../YearMakeModel/styled";

// LICENSE_PLATE_PRODUCT_LIST = "license-plate-product-list",
//   LICENSE_PLATE_PRODUCT_SELECTED = "license-plate-product-selected",

const LicensePlate = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [licensePlate, setLicensePlate] = useState<string>("");
  const [stateId, setStateId] = useState<string>("");
  const [selectedLabel, setSelectedLabel] = useState<string>("");
  const [isError, setIsError] = useState(false);
  const [licensePlateData, setLicensePlateData] = useState<any>(null);
  const [product, setProduct] = useState<{ value: string; label: string }>({
    value: "",
    label: "",
  });
  const [selectedValue, setSelectedValue] = useState<string>("");

  const { state, dispatch } = useGlobalContext();

  const {
    selectorModalConfig,
    store,
    year,
    make,
    model,
    // product
  } = state;

  const hidePartType = selectorModalConfig?.hide_license_plate_product;
  const licensePlateStatePriorities = selectorModalConfig?.license_plate_state_priorities ?? [];

  const licenseKey = selectorModalConfig?.license_key;
  const databaseUrl = selectorModalConfig?.database_url;

  const x2js = new X2JS();

  const handleChangeLicensePlate = (e: ChangeEvent<HTMLInputElement>) => {
    setLicensePlate(e.target.value);
  };

  useEffect(() => {
    const licensePlate =
      getLocalStorageValue(SelectorsLocalStorage.CURRENT_LICENSE_PLATE) || "";
    const state =
      getLocalStorageValue(
        SelectorsLocalStorage.SELECTED_LICENSE_PLATE_STATE
      ) || "";
    const productList = localStorage.getItem(
      SelectorsLocalStorage.LICENSE_PLATE_PRODUCT_LIST
    )
      ? JSON.parse(
          localStorage.getItem(SelectorsLocalStorage.LICENSE_PLATE_PRODUCT_LIST) || ""
        )
      : [];
    const productName = getLocalStorageValue(SelectorsLocalStorage.LICENSE_PLATE_PRODUCT_SELECTED_NAME) || "";
    const productValue = getLocalStorageValue(SelectorsLocalStorage.LICENSE_PLATE_PRODUCT_SELECTED_VALUE) || "";
    if (licensePlate) {
      setLicensePlate(licensePlate);
    }
    if (state) {
      setSelectedLabel(state);
      const stateId = statesList.filter((e) => e.label === state)[0];
      setStateId(stateId.value);
    }
    if(productList) {
      setLicensePlateData(productList)
    }
    if(productName && productValue) {
      setProduct({label: productName, value: productValue})
      setSelectedValue(productName);
    }
  }, []);

  const priorities = selectorModalConfig?.product_category_priorities;

  const getPriorityIndex = (item: any) => {
    // Compare lowercase versions for case-insensitive matching
    const index = priorities.findIndex(
      (priority: string) => priority.toLowerCase() === item.label.toLowerCase()
    );
    // If not found in priorities, return a large number
    return index === -1 ? priorities.length : index;
  };
  const getStatePriorityIndex = (item: any) => {
    // Compare lowercase versions for case-insensitive matching
    const index = licensePlateStatePriorities.findIndex(
      (priority: string) => priority.toLowerCase() === item.label.toLowerCase()
    );
    // If not found in priorities, return a large number
    return index === -1 ? licensePlateStatePriorities.length : index;
  };

  const options = useMemo(() => {
    console.log(licensePlateData, "licensePlateData")
    if (!licensePlateData?.length) {
      const item = licensePlateData;
      if (item?.id) {
        return [{ value: item?.id, label: item?.data }];
      } else {
        return [];
      }
    }
    return licensePlateData?.map((item: any) => ({
      value: item.id,
      label: item.data,
    }));
  }, [licensePlateData]);

  const sortedOptions = options?.slice()
  .sort((a: any, b: any) => {
    const priorityA = getPriorityIndex(a);
    const priorityB = getPriorityIndex(b);

    if (priorityA !== priorityB) {
      return priorityA - priorityB;
    }

    return a.label.localeCompare(b.label);
  });

  const sortedStateList = statesList?.slice()
  .sort((a: any, b: any) => {
    const priorityA = getStatePriorityIndex(a);
    const priorityB = getStatePriorityIndex(b);

    if (priorityA !== priorityB) {
      return priorityA - priorityB;
    }

    return a.label.localeCompare(b.label);
  });

  const getProducts = useCallback(async () => {
    if (!licenseKey || !databaseUrl) return;
    setIsLoading(true);
    const response = await fetchProductsByLicensePlate({
      id: licenseKey,
      url: databaseUrl,
      state: stateId,
      plate: licensePlate,
    });

    const data: any = x2js?.xml2js(response?.data);
    const licensePlateResp = data?.ShowMeTheParts_Product?.product;

    if (!licensePlateResp) {
      setIsError(true);
      setIsLoading(false);
      dispatch({ type: "setData", payload: null });
      return;
    }
    
    setLocalStorageValue(
      SelectorsLocalStorage.LICENSE_PLATE_PRODUCT_LIST,
      JSON.stringify(licensePlateResp || [])
    );

    setLicensePlateData(licensePlateResp);
    setIsLoading(false);
  }, [licenseKey, databaseUrl, stateId, licensePlate]);

  const handleSearch = async () => {
    setIsLoading(true);
    setLocalStorageValue(
      SelectorsLocalStorage.CURRENT_LICENSE_PLATE,
      licensePlate
    );
    if (!licenseKey || !databaseUrl) return;
    const smtpResponse = await fetchSMTPData({
      id: licenseKey,
      url: databaseUrl,
      licensePlate,
      stateId: stateId,
    });
    const data: any = x2js?.xml2js(smtpResponse?.data);
    const smtpResult = data?.ShowMeThePartsVinApps.vinapps;
    if (!smtpResult) {
      setIsError(true);
      setIsLoading(false);
      dispatch({ type: "setData", payload: null });
      return;
    }
    const response: any = await autoPartsSearch(store, {
      year: smtpResult.year,
      make: smtpResult.makeid,
      model: smtpResult.modelid,
      engine: decodeHtml(smtpResult.engineid),
      search_type: "lp",
    });
    if (!response?.data) {
      setIsError(true);
      setIsLoading(false);
      dispatch({ type: "setData", payload: null });
      return;
    }
    dispatch({
      type: "setYear",
      payload: { ...year, value: smtpResult.year, label: smtpResult.year },
    });
    dispatch({
      type: "setMake",
      payload: { ...make, value: smtpResult.makeid, label: smtpResult.make },
    });
    dispatch({
      type: "setModel",
      payload: { ...model, value: smtpResult.modelid, label: smtpResult.model },
    });
    dispatch({
      type: "setProduct",
      payload: { ...product, label: "", value: "" },
    });
    setLocalStorageValue(
      SelectorsLocalStorage.SELECTED_YEAR_KEY_NAME,
      smtpResult.year
    );
    setLocalStorageValue(
      SelectorsLocalStorage.SELECTED_YEAR_VALUE_NAME,
      smtpResult.year
    );
    setLocalStorageValue(
      SelectorsLocalStorage.SELECTED_MAKE_KEY_NAME,
      smtpResult.makeid
    );
    setLocalStorageValue(
      SelectorsLocalStorage.SELECTED_MAKE_VALUE_NAME,
      smtpResult.make
    );
    setLocalStorageValue(
      SelectorsLocalStorage.SELECTED_MODEL_KEY_NAME,
      smtpResult.modelid
    );
    setLocalStorageValue(
      SelectorsLocalStorage.SELECTED_MODEL_VALUE_NAME,
      smtpResult.model
    );
    setLocalStorageValue(SelectorsLocalStorage.SELECTED_PARTTYPE_KEY_NAME, "");
    setLocalStorageValue(
      SelectorsLocalStorage.SELECTED_PARTTYPE_VALUE_NAME,
      ""
    );
    setLocalStorageValue(SelectorsLocalStorage.SELECTED_PRODUCT_KEY_NAME, "");
    setLocalStorageValue(SelectorsLocalStorage.SELECTED_PRODUCT_VALUE_NAME, "");
    setLocalStorageValue(SelectorsLocalStorage.SELECTED_ENGINE_KEY_NAME, "");
    setLocalStorageValue(SelectorsLocalStorage.SELECTED_ENGINE_VALUE_NAME, "");
    setLocalStorageValue(
      SelectorsLocalStorage.SELECTED_LICENSE_PLATE_STATE,
      selectedLabel
    );
    localStorage.setItem(
      SptLocalStorageVariables.SPT_SELECTORS,
      JSON.stringify({
        year: smtpResult.year,
        make: smtpResult.makeid,
        model: smtpResult.modelid,
        product: "",
        engine: decodeHtml(smtpResult.engineid),
      })
    );

    dispatch({ type: "setLicensePlateSearch", payload: true });
    dispatch({ type: "setData", payload: response.data });
    sessionStorage.removeItem(SptLocalStorageVariables.SPT_FILTERS);
    setIsLoading(false);
  };

  const handleProductSearch = async () => {
    setIsLoading(true);
    setLocalStorageValue(
      SelectorsLocalStorage.CURRENT_LICENSE_PLATE,
      licensePlate
    );
    if (!licenseKey || !databaseUrl) return;
    const smtpResponse = await fetchSMTPData({
      id: licenseKey,
      url: databaseUrl,
      licensePlate,
      stateId: stateId,
    });
    const data: any = x2js?.xml2js(smtpResponse?.data);
    const smtpResult = data?.ShowMeThePartsVinApps.vinapps;
    if (!smtpResult) {
      setIsError(true);
      setIsLoading(false);
      dispatch({ type: "setData", payload: null });
      return;
    }
    const response: any = await autoPartsSearch(store, {
      year: smtpResult.year,
      make: smtpResult.makeid,
      model: smtpResult.modelid,
      product: product.value,
      engine: decodeHtml(smtpResult.engineid),
      search_type: "lp",
    });
    if (!response?.data) {
      setIsError(true);
      setIsLoading(false);
      dispatch({ type: "setData", payload: null });
      return;
    }
    dispatch({
      type: "setYear",
      payload: { ...year, value: smtpResult.year, label: smtpResult.year },
    });
    dispatch({
      type: "setMake",
      payload: { ...make, value: smtpResult.makeid, label: smtpResult.make },
    });
    dispatch({
      type: "setModel",
      payload: { ...model, value: smtpResult.modelid, label: smtpResult.model },
    });
    dispatch({
      type: "setProduct",
      payload: { ...product, label: product.label, value: product.value },
    });
    setLocalStorageValue(
      SelectorsLocalStorage.SELECTED_YEAR_KEY_NAME,
      smtpResult.year
    );
    setLocalStorageValue(
      SelectorsLocalStorage.SELECTED_YEAR_VALUE_NAME,
      smtpResult.year
    );
    setLocalStorageValue(
      SelectorsLocalStorage.SELECTED_MAKE_KEY_NAME,
      smtpResult.makeid
    );
    setLocalStorageValue(
      SelectorsLocalStorage.SELECTED_MAKE_VALUE_NAME,
      smtpResult.make
    );
    setLocalStorageValue(
      SelectorsLocalStorage.SELECTED_MODEL_KEY_NAME,
      smtpResult.modelid
    );
    setLocalStorageValue(
      SelectorsLocalStorage.SELECTED_MODEL_VALUE_NAME,
      smtpResult.model
    );
    setLocalStorageValue(SelectorsLocalStorage.SELECTED_PARTTYPE_KEY_NAME, "");
    setLocalStorageValue(
      SelectorsLocalStorage.SELECTED_PARTTYPE_VALUE_NAME,
      ""
    );
    setLocalStorageValue(
      SelectorsLocalStorage.SELECTED_PRODUCT_KEY_NAME,
      product.value
    );
    setLocalStorageValue(
      SelectorsLocalStorage.SELECTED_PRODUCT_VALUE_NAME,
      product.label
    );
    setLocalStorageValue(SelectorsLocalStorage.SELECTED_ENGINE_KEY_NAME, "");
    setLocalStorageValue(SelectorsLocalStorage.SELECTED_ENGINE_VALUE_NAME, "");
    setLocalStorageValue(
      SelectorsLocalStorage.SELECTED_LICENSE_PLATE_STATE,
      selectedLabel
    );
    setLocalStorageValue(
      SelectorsLocalStorage.LICENSE_PLATE_PRODUCT_SELECTED_NAME,
      product.label
    );
    setLocalStorageValue(
      SelectorsLocalStorage.LICENSE_PLATE_PRODUCT_SELECTED_VALUE,
      product.value
    );
    localStorage.setItem(
      SptLocalStorageVariables.SPT_SELECTORS,
      JSON.stringify({
        year: smtpResult.year,
        make: smtpResult.makeid,
        model: smtpResult.modelid,
        product: product.value,
        engine: decodeHtml(smtpResult.engineid),
      })
    );

    dispatch({ type: "setLicensePlateSearch", payload: true });
    dispatch({ type: "setData", payload: response.data });
    sessionStorage.removeItem(SptLocalStorageVariables.SPT_FILTERS);
    setIsLoading(false);
  };

  const handleChange = ({ value, label }: { value: string; label: string }) => {
    setStateId(value);
    setSelectedLabel(label);
  };

  useEffect(() => {
    if (isError) {
      const timer = setTimeout(() => {
        setIsError(false);
      }, 5000); // 5000ms = 5 seconds

      // Clear the timer if the component unmounts or if isError changes before 5 seconds
      return () => clearTimeout(timer);
    }
  }, [isError]);

  return (
    <FullWidth>
      {isError ? (
        <div className="search-bar-active-tab-class">
          Can't find any products that fit your search
        </div>
      ) : (
        <></>
      )}
      <Wrapper>
        {isLoading && <Loader />}
        <Input
          id="licensePlate"
          value={licensePlate}
          onChange={handleChangeLicensePlate}
          placeholder="License plate"
        />
        <Dropdown
          value={selectedLabel}
          onChange={({ value, label }) => handleChange({ value, label })}
          optionsList={sortedStateList}
          placeholder={"Select License Plate State"}
          searchPlaceholder="Search..."
        />
        <SearchButtonStyled
          type="button"
          className="search-bar-search-button-class"
          onClick={hidePartType ? handleSearch : getProducts}
          disabled={!licensePlate || !stateId}
          marginLeft={0}
        >
          {hidePartType ? "Search" : "Find Products"}
        </SearchButtonStyled>
        {!hidePartType && (
          <Dropdown
            value={selectedValue}
            onChange={({ value, label }) => {
              setProduct({ value, label });
              setSelectedValue(label);
            }}
            optionsList={sortedOptions}
            placeholder={isLoading ? "Loading..." : "Select Product"}
            searchPlaceholder="Search..."
            disabled={!sortedOptions.length}
          />
        )}
        {!hidePartType && (
          <SearchButtonStyled
            type="button"
            className="search-bar-search-button-class"
            onClick={handleProductSearch}
            disabled={!product.value}
            marginLeft={0}
          >
            Search
          </SearchButtonStyled>
        )}
      </Wrapper>
    </FullWidth>
  );
};

export default LicensePlate;
