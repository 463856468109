import React, { Dispatch, ReactNode, useContext } from "react";
import {
  SelectorsLocalStorage,
  SptLocalStorageVariables,
} from "../common/constants/localStorage";
import { getLocalStorageValue } from "../common/helpers";

export interface Item {
  value: string;
  label: string;
  isLoading?: boolean;
  isError?: boolean;
}

// Define the shape of the state
export interface GlobalState {
  searchTerm: any;
  licensePlateSearch: boolean;
  selectorModalConfig: any;
  data: any;
  store: string | null;
  collapsed: boolean;
  isConfigLoading: boolean;
  filters: any;
  year: Item;
  make: Item;
  model: Item;
  product: Item;
  productCategory: Item;
  engine: Item;
  yearOptions: Array<Option> | Option;
  makeOptions: Array<Option> | Option;
  modelOptions: Array<Option> | Option;
  productOptions: Array<Option> | Option;
  productCategoriesOptions: Array<Option> | Option;
  engineOptions: Array<Option> | Option;
  apiError: boolean;
}

export interface Option {
  id: string;
  data: string;
}

// Define action types
type GlobalAction = {
  type:
    | "setData"
    | "setStore"
    | "setSearchTerm"
    | "setLicensePlateSearch"
    | "setFilters"
    | "setCollapsed"
    | "setYear"
    | "setMake"
    | "setModel"
    | "setProduct"
    | "setProductCategory"
    | "setEngine"
    | "setYearOptions"
    | "setMakeOptions"
    | "setModelOptions"
    | "setProductOptions"
    | "setProductCategoriesOptions"
    | "setEngineOptions"
    | "setApiError"
    | "setSelectorModalConfig"
    | "setIsConfigLoading";
  payload: any;
};

// Create context type
export interface GlobalContextType {
  state: GlobalState;
  dispatch: Dispatch<GlobalAction>;
}

const storageSelectors = localStorage.getItem(
  SptLocalStorageVariables.SPT_SELECTORS
)
  ? JSON.parse(
      localStorage.getItem(SptLocalStorageVariables.SPT_SELECTORS) || ""
    )
  : {};

const sessionData = localStorage.getItem(
  SptLocalStorageVariables.SPT_SELECTED_FILTERS
)
  ? JSON.parse(
      localStorage.getItem(SptLocalStorageVariables.SPT_SELECTED_FILTERS) || ""
    )
  : {};

const defaultState: GlobalState = {
  searchTerm: storageSelectors,
  selectorModalConfig: null,
  data: null,
  store: null,
  collapsed: false,
  isConfigLoading: false,
  filters: sessionData,
  year: {
    value: getLocalStorageValue(
      SelectorsLocalStorage.SELECTED_YEAR_KEY_NAME
    ).trim(),
    label: getLocalStorageValue(
      SelectorsLocalStorage.SELECTED_YEAR_VALUE_NAME
    ).trim(),
    isLoading: false,
    isError: false,
  },
  make: {
    value: getLocalStorageValue(
      SelectorsLocalStorage.SELECTED_MAKE_KEY_NAME
    ).trim(),
    label: getLocalStorageValue(
      SelectorsLocalStorage.SELECTED_MAKE_VALUE_NAME
    ).trim(),
    isLoading: false,
    isError: false,
  },
  model: {
    value: getLocalStorageValue(
      SelectorsLocalStorage.SELECTED_MODEL_KEY_NAME
    ).trim(),
    label: getLocalStorageValue(
      SelectorsLocalStorage.SELECTED_MODEL_VALUE_NAME
    ).trim(),
    isLoading: false,
    isError: false,
  },
  product: {
    value: getLocalStorageValue(
      SelectorsLocalStorage.SELECTED_PARTTYPE_KEY_NAME
    ).trim(),
    label: getLocalStorageValue(
      SelectorsLocalStorage.SELECTED_PARTTYPE_VALUE_NAME
    ).trim(),
    isLoading: false,
    isError: false,
  },
  productCategory: {
    value: getLocalStorageValue(
      SelectorsLocalStorage.SELECTED_PRODUCT_KEY_NAME
    ).trim(),
    label: getLocalStorageValue(
      SelectorsLocalStorage.SELECTED_PRODUCT_VALUE_NAME
    ).trim(),
    isLoading: false,
    isError: false,
  },
  engine: {
    value: getLocalStorageValue(
      SelectorsLocalStorage.SELECTED_ENGINE_KEY_NAME
    ).trim(),
    label: getLocalStorageValue(
      SelectorsLocalStorage.SELECTED_ENGINE_VALUE_NAME
    ).trim(),
    isLoading: false,
    isError: false,
  },
  yearOptions: [],
  makeOptions: [],
  modelOptions: [],
  productOptions: [],
  productCategoriesOptions: [],
  engineOptions: [],
  apiError: false,
  licensePlateSearch: false,
};

// Create the context with an initial value of undefined
const GlobalContext = React.createContext<GlobalContextType | undefined>(
  undefined
);

function GlobalReducer(state: GlobalState, action: GlobalAction): GlobalState {
  switch (action.type) {
    case "setSearchTerm": {
      return { ...state, searchTerm: action.payload };
    }
    case "setLicensePlateSearch": {
      return { ...state, licensePlateSearch: action.payload };
    }
    case "setFilters": {
      return { ...state, filters: action.payload };
    }
    case "setData": {
      return { ...state, data: action.payload };
    }
    case "setStore": {
      return { ...state, store: action.payload };
    }
    case "setCollapsed": {
      return { ...state, collapsed: action.payload };
    }
    case "setYear": {
      return { ...state, year: action.payload };
    }
    case "setMake": {
      return { ...state, make: action.payload };
    }
    case "setModel": {
      return { ...state, model: action.payload };
    }
    case "setProduct": {
      return { ...state, product: action.payload };
    }
    case "setProductCategory": {
      return { ...state, productCategory: action.payload };
    }
    case "setProductCategoriesOptions": {
      return { ...state, productCategoriesOptions: action.payload };
    }
    case "setEngine": {
      return { ...state, engine: action.payload };
    }
    case "setYearOptions": {
      return { ...state, yearOptions: action.payload };
    }
    case "setMakeOptions": {
      return { ...state, makeOptions: action.payload };
    }
    case "setModelOptions": {
      return { ...state, modelOptions: action.payload };
    }
    case "setProductOptions": {
      return { ...state, productOptions: action.payload };
    }
    case "setEngineOptions": {
      return { ...state, engineOptions: action.payload };
    }
    case "setApiError": {
      return { ...state, apiError: action.payload };
    }
    case "setSelectorModalConfig": {
      return { ...state, selectorModalConfig: action.payload };
    }
    case "setIsConfigLoading": {
      return { ...state, isConfigLoading: action.payload };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

// Define props for GlobalProvider
interface GlobalProviderProps {
  children: ReactNode;
}

function GlobalProvider({ children }: GlobalProviderProps) {
  const [state, dispatch] = React.useReducer(GlobalReducer, defaultState);

  // NOTE: you *might* need to memoize this value
  const value = { state, dispatch };
  return (
    <GlobalContext.Provider value={value}>{children}</GlobalContext.Provider>
  );
}

export const useGlobalContext = () => {
  const context = useContext(GlobalContext);
  if (context === undefined) {
    throw new Error("useGlobalContext must be used within a GlobalProvider");
  }
  return context;
};

export { GlobalProvider };
