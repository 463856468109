import {
  OptionItem,
  QuantityOptions,
  QuantityPickerWrapper,
  QuantityText,
  RelativeWrapper,
} from "./styled";
import { ArrowDown } from "../../assets/arrowDown";
import { useState } from "react";

interface QunatityPickerProps {
  quantity: number;
  handleChangeQuantity: (quantity: number) => void;
  maxQuantity: number;
}

const QuantityPicker = ({
  quantity,
  handleChangeQuantity,
  maxQuantity,
}: QunatityPickerProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const handleClick = () => {
    setIsOpen(!isOpen);
  };
  const handleChange = (quantity: number) => {
    handleChangeQuantity(quantity);
    setIsOpen(false);
  };
  const rangeArray = Array.from(
    { length: maxQuantity },
    (_, index) => index + 1
  );
  return (
    <RelativeWrapper>
      <QuantityPickerWrapper onClick={handleClick}>
        <QuantityText>{`Qty: ${quantity}`}</QuantityText>
        <ArrowDown />
      </QuantityPickerWrapper>
      {isOpen && (
        <QuantityOptions>
          {rangeArray.map((e) => (
            <OptionItem key={e} onClick={() => handleChange(e)}>
              {e}
            </OptionItem>
          ))}
        </QuantityOptions>
      )}
    </RelativeWrapper>
  );
};

export default QuantityPicker;
