import { StyledSliderButton } from "./styled";
import { ArrowLeft } from "../../assets/arrowLeft";
import { ArrowRight } from "../../assets/arrowRight";

const SliderButton = ({ prev = false, onClick, disabled = false }: { prev?: boolean; onClick: () => void; disabled: boolean }) => {
  return (
    <StyledSliderButton onClick={onClick} prev={prev} disabled={disabled}>
      {prev ? <ArrowLeft color={disabled ? "#EAECF0" : "#000000"} /> : <ArrowRight color={disabled ? "#EAECF0" : "#000000"} />}
    </StyledSliderButton>
  );
};
export default SliderButton;
