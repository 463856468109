export const ArrowLeft = ({ color = "#000000" }: { color?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="9"
      height="14"
      viewBox="0 0 9 14"
      fill="none"
    >
      <path
        d="M8.22833 13.1321C8.33581 13.0248 8.42108 12.8974 8.47926 12.7572C8.53744 12.6169 8.56738 12.4666 8.56738 12.3147C8.56738 12.1629 8.53744 12.0125 8.47926 11.8723C8.42108 11.732 8.33581 11.6046 8.22833 11.4974L3.73 6.99904L8.22833 2.50071C8.33567 2.39337 8.42081 2.26595 8.4789 2.1257C8.53699 1.98546 8.56689 1.83515 8.56689 1.68336C8.56689 1.53156 8.53699 1.38125 8.4789 1.24101C8.42081 1.10077 8.33567 0.97334 8.22833 0.866004C8.121 0.758668 7.99357 0.673527 7.85333 0.615437C7.71309 0.557346 7.56278 0.527448 7.41098 0.527448C7.25919 0.527448 7.10888 0.557346 6.96863 0.615437C6.82839 0.673527 6.70097 0.758668 6.59363 0.866004L1.27215 6.18749C1.16467 6.29474 1.0794 6.42215 1.02122 6.5624C0.963044 6.70265 0.933097 6.853 0.933097 7.00484C0.933097 7.15668 0.963044 7.30703 1.02122 7.44728C1.0794 7.58753 1.16467 7.71493 1.27215 7.82219L6.59363 13.1437C7.03419 13.5842 7.77618 13.5842 8.22833 13.1321Z"
        fill={color}
      />
    </svg>
  );
};
