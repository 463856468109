import { styled } from "styled-components";
import { StyledProps } from "../../common/types";
import { colors } from "../../common/constants/colors";

export const CardStyled = styled.div<StyledProps>`
  width: 100%;
  border-style: solid !important;
  border-color: ${({ borderColor }) => borderColor || colors.grey300};
  background: ${({ backgroundColor }) => backgroundColor || "#ffffff"};
  border-width: ${({ borderWidth }) => borderWidth || "1px"};
  border-radius: ${({ borderRadius }) => borderRadius || "10px"};
  display: flex !important;
  flex-direction: row !important;
  text-decoration: none !important;
  color: ${colors.black};
  gap: 30px;
  padding: 10px;
`;

export const InfoWrapper = styled.div`
  width: calc(100% - 210px);
`;

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const CardHeader = styled.div<StyledProps>`
  color: ${({ color }) => color || "#000"} !important;

  // font-family: Inter !important;
  font-size: ${({ fontSize }) => fontSize || "18px"} !important;
  font-style: normal;
  font-weight: ${({ fontWeight }) => fontWeight || 700} !important;
  line-height: 150%;
`;

export const TextWrapper = styled.div`
  flex-direction: row;
  display: flex;
  align-items: start;
`;

export const AddToCartWrapper = styled.div`
  min-width: 260px;
  margin-left: 12px;
`;

export const Wrapper = styled.div`
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  @media (max-width: 900px) {
    flex-direction: column;
    justify-content: start;
  }
  @media (min-width: 1024px) {
    flex-direction: column;
    justify-content: start;
  }
  @media (min-width: 1180px) {
    flex-direction: row;
  }
`;

export const VariationsWrapper = styled.div`
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
`;

export const BrandTypography = styled.div`
  color: #344054;
  // font-family: Inter !important;
  font-size: 12px !important;
  font-style: normal;
  font-weight: 600 !important;
  line-height: 160%;
`;

export const BrandName = styled.div<StyledProps>`
  color: #344054;
  // font-family: Inter !important;
  font-size: 12px !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 160%;
  display: inline;
`;

export const CurrencyName = styled.div<StyledProps>`
  color: #000;

  // font-family: Inter !important;
  font-size: 13px !important;
  font-style: normal;
  font-weight: 700 !important;
  line-height: 160%;
  display: inline;
`;

export const QuantityWrapper = styled.div`
  flex-direction: row;
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const AddButton = styled.div`
  border-radius: 5px;
  background: #000;
  display: flex;
  padding: 6px 10px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: #fff;
  text-align: center;
  // font-family: Inter !important;
  font-size: 13px !important;
  font-style: normal;
  font-weight: 600 !important;
  line-height: 160%;
`;

export const PartFit = styled.div`
  display: flex;
  padding: 2px 5px;
  align-items: center;
  width: fit-content;
  gap: 8px;
  border-radius: 5px;
  background: rgba(2, 122, 72, 0.1);
  margin: 10px 0px;
`;

export const PartFitText = styled.div`
  color: #027a48;
  // font-family: Inter !important;
  font-size: 13px !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 160%;
`;

export const SectionHeader = styled.div`
  color: #000;

  // font-family: Inter !important;
  font-size: 18px !important;
  font-style: normal;
  font-weight: 600 !important;
  line-height: 160%;
`;

export const CardLink = styled.a`
  cursor: pointer;
  display: flex;
  text-decoration: none !important;
  margin-bottom: 10px;
  &:hover {
    opacity: 0.8;
  }
`;

export const LinkWrapper = styled.a`
  cursor: pointer;
  text-decoration: none !important;
  &:hover {
    opacity: 0.8;
  }
`;

export const ImageWrapper = styled.div<StyledProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px;
  > img {
    max-width: 180px !important;
    aspect-ratio: initial;
    border-top-left-radius: ${({ borderRadius }) => borderRadius};
    border-top-right-radius: ${({ borderRadius }) => borderRadius};
  }
`;
export const RelativeWrapper = styled.div`
  position: relative;
`;

export const QuantityPickerWrapper = styled.div`
  display: flex;
  align-items: center;
  border-radius: 5px;
  border: 1px solid ${colors.grey300};
  padding: 6px 5px 6px 8px;
  cursor: pointer;
`;

export const QuantityText = styled.div`
  color: #000;
  text-align: center;
  // font-family: Inter !important;
  font-size: 11px !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 160%;
  height: 18px;
`;

export const QuantityOptions = styled.div`
  display: flex;
  z-index: 10;
  width: 120px;
  height: 150px;
  overflow: scroll;
  padding: 5px;
  flex-direction: column;
  align-items: flex-start;
  position: absolute;
  top: 35px;
  right: 0px;
  border-radius: 6px;
  border: 1px solid ${colors.grey300};
  background: #fff;
  box-shadow: 0px 20px 35px 0px rgba(0, 0, 0, 0.1);
  cursor: options;
`;

export const OptionItem = styled.div`
  display: flex;
  padding: 5px 10px;
  align-items: center;
  gap: 15px;
  align-self: stretch;
  border-radius: 3px;
  background: #fff;
  color: #000;
  // font-family: Inter !important;
  font-size: 11px !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 160%;
  &:hover {
    background: ${colors.grey200};
  }
`;

export const VariationWrapper = styled.div`
  display: flex;
  gap: 12px;
  grid-auto-rows: max-content;
`;

export const SliderContainer = styled.div`
  width: 100%;
  overflow: hidden;
`;

export const FlexContainer = styled.div`
  display: flex;
  flex-wrap: nowrap; /* Prevent wrapping, keep all items in one row */
  gap: 12px;
`;

export const FlexItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 0 0 47%;
  min-width: 47%;
  gap: 12px;
  @media (min-width: 750px) {
    flex: 0 0 23%;
    min-width: 23%;
  }
`;

export const VariationCard = styled.div<StyledProps>`
  display: flex;
  padding: 4px 8px;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
  flex: 1 0 0;
  border-radius: 5px;
  border: 1px solid ${colors.grey200};
  background: ${({ backgroundColor }) => backgroundColor || colors.grey50};
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.05);
  width: 100%;
`;

export const VariantHeading = styled.div<StyledProps>`
  color: ${({ color }) => color || "#000"} !important;
  display: ${({ display }) => display || "block"};

  // font-family: Inter !important;
  font-size: 12px !important;
  font-style: normal;
  font-weight: 600 !important;
  line-height: 160%;
`;

export const VariantDescription = styled.div<StyledProps>`
  color: ${({ color }) => color || colors.grey700} !important;
  display: ${({ display }) => display || "block"};
  // font-family: Inter !important;
  font-size: 12px !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 160%;
`;

export const StyledSliderButton = styled.div<{
  prev: boolean;
  disabled: boolean;
}>`
  display: flex;
  width: 32px;
  height: 32px;
  padding: ${({ prev }) => (prev ? "0px 2px 0px 0px" : "0px 0px 0px 2px")};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  background: ${({ disabled }) => (disabled ? "transparent" : colors.grey100)};
  cursor: pointer;
`;

export const SliderButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;
