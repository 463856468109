export const ArrowRight = ({ color = "#000000" }: { color?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="9"
      height="14"
      viewBox="0 0 9 14"
      fill="none"
    >
      <path
        d="M1.27167 0.867921C1.16419 0.975178 1.07892 1.10258 1.02074 1.24283C0.962564 1.38308 0.932617 1.53343 0.932617 1.68527C0.932617 1.83711 0.962564 1.98746 1.02074 2.12771C1.07892 2.26797 1.16419 2.39537 1.27167 2.50262L5.77 7.00096L1.27167 11.4993C1.16433 11.6066 1.07919 11.7341 1.0211 11.8743C0.963007 12.0145 0.933108 12.1648 0.933108 12.3166C0.933108 12.4684 0.963007 12.6187 1.0211 12.759C1.07919 12.8992 1.16433 13.0267 1.27167 13.134C1.379 13.2413 1.50643 13.3265 1.64667 13.3846C1.78691 13.4427 1.93722 13.4726 2.08902 13.4726C2.24081 13.4726 2.39112 13.4427 2.53137 13.3846C2.67161 13.3265 2.79903 13.2413 2.90637 13.134L8.22785 7.81251C8.33533 7.70526 8.4206 7.57785 8.47878 7.4376C8.53696 7.29735 8.5669 7.147 8.5669 6.99516C8.5669 6.84332 8.53696 6.69297 8.47878 6.55272C8.4206 6.41247 8.33533 6.28507 8.22785 6.17781L2.90637 0.856327C2.46581 0.415769 1.72382 0.415769 1.27167 0.867921Z"
        fill={color}
      />
    </svg>
  );
};
