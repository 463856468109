import { styled } from "styled-components";
import { StyledProps } from "../../common/types";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  @media (max-width: 980px) {
    flex-direction: column;
  }
`;
export const DropdownSectionStyled = styled.div<StyledProps>`
  width: 100%;
  max-width: 1100px;
  display: grid;
  gap: 10px;
  grid-template-columns: ${({ gridStyle }) =>
    gridStyle ||
    "minmax(110px, max-content) minmax(130px, max-content) minmax(110px, max-content) minmax(110px, 400px) minmax(110px, 1fr)"};
  @media (max-width: 980px) {
    gap: 5px;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(5, max-content);
  }
`;

export const FullWidth = styled.div`
  width: 100%;
`;

export const SearchButtonStyled = styled.button<StyledProps>`
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  padding: 8px 16px !important;
  margin-left: 12px !important;
  border-radius: 8px !important;
  background-color: ${({ backgroundColor }) =>
    backgroundColor || "#1d4ed8"} !important;
  color: ${({ color }) => color || "#ffffff"} !important;
  font-size: ${({ fontSize }) => fontSize || "14px"} !important;
  font-weight: ${({ fontWeight }) => fontWeight || 600} !important;
  width: 100px !important;
  height: 40px !important;
  cursor: pointer !important;

  &:hover {
    box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 0px 10em inset;
    background-color: #969696;
  }

  &:disabled {
    background-color: #cdcdcd !important;
    cursor: not-allowed !important;
    &:hover {
      box-shadow: none;
    }
  }

  @media (max-width: 980px) {
    margin-top: 5px !important;
    margin-left: 0 !important;
    width: 100% !important;
    justify-content: center !important;
  }
`;

export const ClearButtonStyled = styled.button<StyledProps>`
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  padding: 8px 16px !important;
  margin-left: 12px !important;
  border-radius: 8px !important;
  background-color: ${({ backgroundColor }) =>
    backgroundColor || "#ff9300"} !important;
  color: ${({ color }) => color || "#ffffff"} !important;
  font-size: ${({ fontSize }) => fontSize || "14px"} !important;
  font-weight: ${({ fontWeight }) => fontWeight || 600} !important;
  width: 100px !important;
  height: 40px !important;
  font-weight: 600 !important;
  cursor: pointer !important;

  &:hover {
    box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 0px 10em inset;
  }
  &:disabled {
    background-color: #cdcdcd !important;
    cursor: not-allowed !important;
    box-shadow: rgba(16, 24, 40, 0.12) 1px 1px 2px;
    &:hover {
      box-shadow: none;
    }
  }

  @media (max-width: 980px) {
    margin-top: 5px !important;
    margin-left: 0 !important;
    width: 100% !important;
    justify-content: center !important;
  }
`;
