export const GreenCheck = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
    >
      <path
        d="M8 1.5C6.61553 1.5 5.26216 1.91054 4.11101 2.67971C2.95987 3.44888 2.06266 4.54213 1.53285 5.82122C1.00303 7.1003 0.86441 8.50777 1.13451 9.86563C1.4046 11.2235 2.07129 12.4708 3.05026 13.4497C4.02922 14.4287 5.2765 15.0954 6.63437 15.3655C7.99224 15.6356 9.39971 15.497 10.6788 14.9672C11.9579 14.4373 13.0511 13.5401 13.8203 12.389C14.5895 11.2378 15 9.88447 15 8.5C15 6.64349 14.2625 4.86301 12.9498 3.55025C11.637 2.2375 9.85652 1.5 8 1.5ZM7 11.295L4.5 8.795L5.295 8L7 9.705L10.705 6L11.503 6.793L7 11.295Z"
        fill="#027A48"
      />
    </svg>
  );
};
