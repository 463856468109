import axios from "axios";
import {
  DefaultPartsProps,
  FetchEnginesProps,
  FetchMakesProps,
  FetchModelsProps,
  FetchPartTypesProps,
  FetchProductsByVinProps,
} from "../common/types/showMeThePartsTypes";

export const fetchYears = async ({ url, id }: DefaultPartsProps) => {
  return axios
    .get(`https://${url}?lookup=year&id=${id}`, {
      timeout: 3000,
    })
    .catch((error) => {
      console.log("Error", error.message);
    });
};

export const fetchMakes = async ({ url, id, year }: FetchMakesProps) => {
  return axios
    .get(`https://${url}?lookup=make&year=${year}&id=${id}`, {
      timeout: 3000,
    })
    .catch((error) => {
      console.log("Error", error.message);
    });
};

export const fetchModels = async ({
  url,
  id,
  year,
  make,
}: FetchModelsProps) => {
  return axios
    .get(`https://${url}?lookup=model&year=${year}&make=${make}&id=${id}`, {
      timeout: 3000,
    })
    .catch((error) => {
      console.log("Error", error.message);
    });
};

export const fetchPartTypes = async ({
  url,
  id,
  year,
  make,
  model,
}: FetchPartTypesProps) => {
  return axios
    .get(
      `https://${url}?lookup=parttype&year=${year}&make=${make}&model=${model}&id=${id}`,
      {
        timeout: 3000,
      }
    )
    .catch((error) => {
      console.log("Error", error.message);
    });
};
export const fetchProductsTypes = async ({
  url,
  id,
  year,
  make,
  model,
}: FetchPartTypesProps) => {
  return axios
    .get(
      `https://${url}?lookup=product&year=${year}&make=${make}&model=${model}&id=${id}`,
      {
        timeout: 3000,
      }
    )
    .catch((error) => {
      console.log("Error", error.message);
    });
};
export const fetchEngines = async ({
  url,
  id,
  year,
  make,
  model,
  product,
}: FetchEnginesProps) => {
  return axios
    .get(
      `https://${url}?lookup=engine&year=${year}&make=${make}&model=${model}&product=${product}&id=${id}`,
      {
        timeout: 3000,
      }
    )
    .catch((error) => {
      console.log("Error", error.message);
    });
};

export const fetchProductsByVin = async ({
  url,
  id,
  vin,
}: FetchProductsByVinProps) => {
  return axios
    .get(`https://${url}?lookup=vinappproducts&id=${id}&vin=${vin}`, {
      timeout: 3000,
    })
    .catch((error) => {
      console.log("Error", error.message);
    });
};
